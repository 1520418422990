import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Inflation = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Inflation" showCalc={false} />
            <Article>
                <p>
                    Drohend schwebt der Begriff über den Köpfen sämtlicher Kreditnehmer*innen: Inflation. Doch was ist
                    darunter eigentlich genau zu verstehen? Ist Inflation aus wirtschaftlicher Sicht immer etwas
                    Negatives? Und wie wirkt sie sich konkret auf dein Finanzierungsvorhaben aus? Diese Fragen klären
                    wir im folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist Inflation? – Eine kurze Definition</h2>
                <p>
                    Inflation – auch Preisinflation oder Teuerung – liegt dann vor, wenn das allgemeine Preisniveau
                    einer Wirtschaft steigt. Die Auswirkungen dieses Prozesses treffen nicht nur Unternehmen, sondern
                    auch Privatpersonen: Erhöhen sich die Kosten für Waren und Dienstleistungen. So kannst du dir mit
                    deinem Einkommen entsprechend weniger leisten. Mit dem Anstieg der Preise geht also eine Abnahme
                    deiner Kaufkraft pro Euro einher.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was sind die Gründe für eine Inflation?</h2>
                <p>
                    Als Gründe für eine niedrige bis moderate Ausprägung lassen sich insbesondere Schwankungen in der
                    Verfügbarkeit von sowie der Nachfrage nach Gütern und Dienstleistungen anführen. Hält die
                    Inflationsperiode allerdings länger an, so liegt die Wachstumsrate der Geldmenge meist über dem
                    allgemeinen Wirtschaftswachstum. Folglich lassen sich zumindest drei Arten der Inflation
                    unterscheiden:
                </p>
                <ul>
                    <li>
                        <strong>Angebotsinflation:</strong> Diese Form liegt dann vor, wenn es zu einer Knappheit von
                        Gütern und/oder Dienstleistungen kommt. Die Produkte, die noch verfügbar sind, werden zu
                        entsprechend höheren Preisen verkauft.
                    </li>
                    <li>
                        <strong>Nachfrageinflation:</strong> In diesem Fall kaufen Kosument*innen – meist aufgrund einer
                        Krisensituation – plötzlich in sehr kurzer Zeit eine große Anzahl bestimmter Produkte. Da der
                        erhöhten Nachfrage vonseiten der Produktion nicht nachgekommen werden kann, steigen die Preise
                        dieser Waren bzw. Dienstleistungen.
                    </li>
                    <li>
                        <strong>Geldmengeninflation:</strong> Auch die Geldmenge, die zu einem bestimmten Zeitpunkt im
                        Umlauf ist, hat – wie bereits angedeutet – Einfluss auf die Inflation. Je mehr Geld in einer
                        Wirtschaft zirkuliert, umso weniger können sich die Konsument*innen pro Geldeinheit leisten.
                        Werden also – etwa um das Wirtschaftswachstum in einer bestimmten Region zu stimulieren – von
                        einer Zentralbank vergleichsweise große Mengen Geldes in Umlauf gebracht, so hat diese
                        finanzpolitische Maßnahme ebenfalls eine vorübergehende Teuerung zur Folge.
                    </li>
                </ul>
                <p>
                    Nachdem wir nun wissen, welche Faktoren eine Inflation bedingen können, widmen wir uns im folgenden
                    Abschnitt den positiven und negativen Folgen der Geldentwertung.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt unverbindliches Beratungsgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Welche Effekte hat die Inflation im Allgemeinen?</h2>
                <p>
                    Ein – in wirtschaftlicher wie in sozialer Hinsicht – derart komplexes Phänomen wie die Inflation hat
                    natürlich sowohl positive als auch negative Facetten. Je nach der wirtschaftlichen Position, in der
                    man sich als Konsumet*in befindet, wird man auf die steigenden Preise mehr oder weniger gelassen
                    reagieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Positive Folgen</h3>
                <p>
                    Eine leichte bis moderate Inflationsrate – ideal sind etwa 2% – hat grundsätzlich einen positiven
                    Effekt auf die allgemeine Wirtschaftslage: Da die Konsument*innen von einer Entwertung ihres
                    Kapitals ausgehen, sind sie eher bereit, Geld auszugeben – dies trägt zum Wirtschaftswachstum bei.
                    Unter Privatpersonen sind es u.a. Schuldner*innen, die von der Inflation profitieren: Zwar bleibt
                    die nominale Summe der Schulden unverändert, ihr realer Wert nimmt jedoch ab. Auch wer sich im
                    Besitz großer Vermögenswerte befindet, die sich beispielsweise in Immobilien anlegen lassen, muss
                    sich vor der Geldentwertung nicht fürchten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Sichere dir jetzt eine Finanzierung
                    </a>
                </p>
                <hr />

                <h3>Negative Folgen</h3>
                <p>
                    Selbstverständlich hat die Inflation jedoch nicht nur Profiteur*innen. Im Gegenteil: Für jede*n,
                    der*die über ein (festes) Einkommen verfügt, bedeutet sie einen erheblichen Nachteil – kann man sich
                    mit eigenen Gehalt durch den allgemeinen Anstieg der Preise für Waren und Dienstleistungen doch
                    sukzessive weniger leisten als zuvor. In besonderem Maße davon betroffen sind Pensionist*innen,
                    selbstständig Tätige sowie arbeitslose Personen.
                </p>
                <hr />

                <h2>Welche Auswirkungen hat die aktuelle Inflationslage auf meine Immobilienfinanzierung?</h2>
                <p>
                    Möglicherweise fragst du dich, wie sich die aktuelle Inflationsrate auf deine bestehende oder eine
                    geplante Finanzierung auswirkt. Im Falle eines laufenden Kredits hängt das davon ab, ob du einen
                    Vertrag über einen Fixzinssatz angeschlossen oder eine variable Verzinsung gewählt hast. Bei einem
                    fixen Zinssatz kannst du dich ganz entspannt zurücklehnen – die Bank kann die derzeitige
                    Inflationslage nicht zum Anlass nehmen, deine monatlichen{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    anzuheben. Da die Geldentwertung allerdings auch dein Gehalt betrifft, wird deine Finanzierung
                    leider auch so etwas teurer. Schließlich bedeutet Inflation, wie wir vorhin gesehen haben, dass du
                    dir mit derselben Summe weniger leisten kannst. Anders sieht es bei einem Kredit mit variabler
                    Verzinsung aus: Die Wahl dieses Zinsmodells bedeutet, dass die Bank die monatliche Rate mithilfe der
                    Zinsen an die jeweils aktuelle Inflationslage anpassen kann. Zusätzlich zur Entwertung deines
                    Einkommens durch die Inflation kommt es hier also zu einem Aufschlag auf die monatliche Rate.
                    Sollten die Konditionen deines laufenden Kredits unattraktiv geworden sein, ist vielleicht eine{" "}
                    <Link to="/dienstleistungen/umschuldung/" target="_blank" rel="noreferrer noopener">
                        Umschuldung
                    </Link>{" "}
                    das Richtige für dich.
                </p>
                <p>
                    Aufgrund der steigenden Inflation werden Immobilienkredite künftig wohl größtenteils mit variablem
                    Zinssatz vergeben – dies dämmt potenzielle Verluste für Kreditinstitute ein, führt aber aufseiten
                    der Kreditnehmer:in zu geringerer Planungssicherheit. Angaben der Österreichischen Nationalbank
                    zufolge, waren mit Ende März bereits 50 % der laufenden Wohnungsfinanizerungen variabel verzinst.
                    Schließt du einen Kreditvertrag mit diesem Zinsmodell ab, ist vor allem eines wichtig:
                    Sicherzustellen, dass du dich mit der monatlichen Rate nicht schon zu Beginn der Laufzeit an der
                    Grenze deiner finanziellen Möglichkeiten bewegst. Du solltest unbedingt über genügend Reserven
                    verfügen, um deine Raten auch im Falle einer Anpassung des Zinssatzes stemmen zu können.Wie der{" "}
                    <a href="https://oesterreich.orf.at/stories/3155113/" target="_blank" rel="noreferrer noopener">
                        ORF
                    </a>{" "}
                    berichtete, gehen Finanzexpert:innen davon aus, dass ein inflationsbedingte Erhöhung des{" "}
                    <Link to="/artikel/leitzins/" target="_blank" rel="noreferrer noopener">
                        Leitzinses
                    </Link>{" "}
                    durch die EZB gerade jenen Konsument:innen Schwierigkeiten bereiten würde, “die bei der Kredithöhe
                    an oder über das Limit gegangen sind und sich gleichzeitig für variable Zinsen entschieden haben”.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Dämme die Folgen der Inflation für deinen Kredit ein – mit miracl!</h2>
                <p>
                    Um die negativen Folgen der Inflation für dich als Kreditnehmer*in zu minimieren, empfiehlt sich die
                    Wahl eines Fixzinssatzes. Im Unterschied zu einer variablen Verzinsung ist dieser gegenüber
                    potenziellen Schwankungen der Zinsen auf dem Finanzmarkt stabil, die ebenfalls von der allgemeinen
                    Preissteigerung mitbedingt werden. Nutze unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    oder vereinbare noch heute dein persönliches Online-Gespräch mit einem*einer unserer
                    Finanzberater*innen, damit ihr gemeinsam eine Finanzierungsoption finden könnt, mit der du nicht nur
                    optimal gegen die Inflation gewappnet, sondern auch sonst rundum zufrieden bist! Wir helfen dir,
                    dein neues Zuhause zu finanzieren – ohne dass du dein altes dabei verlassen musst!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratungsgespräch vereinbaren
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"inflation"}></BreadcrumbList>
            <ArticleStructuredData page={"inflation"} heading={"Inflation"} />
        </Layout>
    );
};

export default Inflation;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.inflation", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
